import React from 'react'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

const ContactPage = () => {
  return (
      <div>
          <Navbar />
      <Contact />
      <Footer />
    </div>
  )
}

export default ContactPage