import React from 'react'
import Login from '../components/Login/Login'

const LoginPage = () => {
  return (
      <div>
          <Login />
    </div>
  )
}

export default LoginPage