import React from 'react'
import SignUp from '../components/SignUp/SignUp'

const SignUpPage = () => {
  return (
      <div>
          <SignUp />
    </div>
  )
}

export default SignUpPage