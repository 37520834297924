import Sidebar from "../components/Sidebar/Sidebar"
import File from "../components/UserFiles/File"


const FilePage = ()=>{
    return (
        <div>
            <Sidebar/>
            <File/>
        </div>
    );
}
export default FilePage;